import {
  Edge,
  EdgeChange,
  NodeChange,
  OnDelete,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from '@xyflow/react';
import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { RESPONSE_CODE } from 'src/utils/constants';
import { isNullOrEmpty } from 'src/utils/stringUtils';
import StoriesServices from '../services/stories.service';

type Props = {
  loading: boolean;
  detail: any;
  isModalOpen: boolean;
  isModalOpenAdd: boolean;
  connectParams: any;
  focusNode: any;
  isNodeEnding: boolean;
};

const useStoryDetail = (id?: string) => {
  const initialData = {
    loading: false,
    detail: undefined,
    isModalOpen: false,
    isModalOpenAdd: false,
    connectParams: undefined,
    focusNode: undefined,
    isNodeEnding: false,
  };
  const [state, setState] = useState<Props>(initialData);
  const [nodes, setNodes] = useState<any[]>([]);
  const [edges, setEdges] = useState<any[]>([]);
  const [isChangeDirection, setIsChangeDirection] = useState(false);


  useEffect(() => {
    if (isChangeDirection) {
      handleChangeDirection();
      setIsChangeDirection(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isChangeDirection, nodes, edges
  ]);


  async function handleChangeDirection() {
      await onSavePosition();
  }

  function convertToInitialNodes(array: any[]) {
    return array?.map((item, index) => ({
      id: item.id,
      type: 'textUpdater',
      position: { x: item.x, y: item.y },
      data: { value: index + 1, item: item },
    }));
  }

  function updateInputArrayWithPositions(
    inputArray: any[],
    initialNodes: any[]
  ) {
    return inputArray.map((item) => {
      const node = initialNodes.find((node) => node.id === item.id);
      if (node) {
        item.nodeId = node.id;
        item.positionX = node.position.x;
        item.positionY = node.position.y;
      }
      return item;
    });
  }

  const showModal = (params: any) => {
    setState((prev) => ({
      ...prev,
      isModalOpen: true,
      connectParams: params,
    }));
  };

  const showModalAdd = (isEnding?: boolean) => {
    setState((prev) => ({
      ...prev,
      isModalOpenAdd: true,
      isNodeEnding: isEnding ?? false,
    }));
  };

  const closeModalAdd = () => {
    setState((prev) => ({
      ...prev,
      isModalOpenAdd: false,
      focusNode: undefined,
    }));
  };

  const closeModal = () => {
    setState((prev) => ({
      ...prev,
      isModalOpen: false,
    }));
    removeLastEdge();
  };

  const removeLastEdge = () => {
    const temp = edges;
    temp.pop();
    setEdges(temp);
  };

  useEffect(() => {
    if (!isNullOrEmpty(id)) {
      getStoryDetail();
    }
  }, [id]);

  const getStoryDetail = async () => {
    if (isNullOrEmpty(id)) {
      return;
    }

    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    const res = await StoriesServices.getStoryDetail(id ?? '');

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        loading: false,
        detail: res?.data,
      }));
      setNodes(convertToInitialNodes(res?.data?.storyNodes));
      setEdges(
        res?.data?.edgeNodes?.map((item: any) => ({
          ...item,
          sourceHandle: item?.sourceHandle + '',
        }))
      );
    } else {
      message.error(res?.error?.errorMessage ?? 'Có lỗi xảy ra');
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const onNodesChange = useCallback(
    (
      changes: NodeChange<{
        id: string;
        type: string;
        position: { x: number; y: number };
        data: { value: number };
      }>[]
    ) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    []
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange<{ id: string; source: string; target: string }>[]) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    []
  );

  const onConnect = useCallback((params: any) => {
    setEdges((eds) => addEdge(params, eds));
    showModal(params);
  }, []);

  const onSavePosition = async (showNoti?:boolean) => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    const changes = updateInputArrayWithPositions(
      state?.detail?.storyNodes,
      nodes
    );
    const res = await StoriesServices.updatePosition(id ?? '', {
      positionNodes: changes,
    });

    if (res?.status === RESPONSE_CODE.SUCCESS ) {
     showNoti && message.success('Lưu thành công.');
      
    } else {
      message.error(res?.error?.errorMessage ?? 'Có lỗi xảy ra.');
    }
    setState((prev) => ({
      ...prev,
      loading: false,
    }));
    // console.log("alo", alo);
  };

  // const changeConnection = () => {
  //   setState((prev) => ({
  //     ...prev,
  //     loading: true,
  //   }));
  // };

  const onCreateConnect = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));

    const res = await StoriesServices.updateConnection({
      nextNodeId: state?.connectParams?.target,
      nodeId: state?.connectParams?.source,
      optionId: state?.connectParams?.sourceHandle,
      storyId: id ?? '',
    });

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      getStoryDetail();
      closeModalAdd();
      setState((prev) => ({
        ...prev,
        connectParams: undefined,
        isModalOpen: false,
      }));
    } else {
      message.error(res?.error?.errorMessage ?? 'Có lỗi xảy ra.');
    }
    setState((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  const onFocusNode = (id: string) => {
    const existItem = state?.detail?.storyNodes?.filter(
      (item: any) => item?.id === id
    );

    if (!isNullOrEmpty(existItem)) {
      setState((prev) => ({
        ...prev,
        focusNode: existItem?.[0],
        isModalOpenAdd: true,
      }));
    }
  };

  const onDeleteNode = async ({nodes ,edges}:{
    nodes: Node[],
    edges: Edge[]
  }) => {
    if (isNullOrEmpty(id) || isNullOrEmpty(edges)) {
      return;
    }
    const nodeId = edges[0].source;
    const optionId = edges[0].sourceHandle;
    const response = await StoriesServices.deleteNode(id!,{
      nodeId,
      optionId
    });
    if(response.status === RESPONSE_CODE.SUCCESS){
      message.success('Xóa thành công');
    } else {
      message.error(response.error?.errorMessage ?? 'Xóa thất bại');
    }
  };

  return {
    state,
    onNodesChange,
    nodes,
    edges,
    onEdgesChange,
    onConnect,
    showModal,
    closeModal,
    showModalAdd,
    closeModalAdd,
    getStoryDetail,
    onSavePosition,
    onCreateConnect,
    onFocusNode,
    setNodes,
    setEdges,
    onDeleteNode,
    setIsChangeDirection
  };
};

export default useStoryDetail;
