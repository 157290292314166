import {
  BookOutlined,
  FileDoneOutlined,
  FileOutlined,
  HomeOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { ReactNode } from "react";
import {
  // PERMISSION_TYPE,
  ROUTERS,
} from "src/utils/constants";

export type MenuItemProps = {
  path?: string;
  name: string;
  icon: ReactNode;
  hidden: boolean;
  children?: {
    path: string;
    name: string;
    hidden: boolean;
  }[];
};

const routes = [
  {
    path: ROUTERS.Home.path,
    name: "Stories",
    key: "stories",
    icon: <BookOutlined />,
    hidden: false,
  },
  // {
  //   path: ROUTERS.Tools.path,
  //   name: "Tool",
  //   key: "tool",
  //   icon: <ToolOutlined />,
  //   hidden: false,
  // },
].filter((item) => !item?.hidden);

const defaultExport = {
  route: {
    path: "/",
    routes: routes,
  },
};

export default defaultExport;
