import { message } from 'antd';
import {RcFile} from 'antd/es/upload';

export const checkImageBeforeUpload = (file: RcFile) => {
  const isCheckTypeImage =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/webp' ||
    file.type === 'image/jpeg';
  if (!isCheckTypeImage) {
    message.error('Bạn chỉ có thể tải lên tệp JPG / JPEG / PNG ? WEBP!');
  }
  const isLt10M = file.size / 1024 / 1024 < 5;
  if (!isLt10M) {
    message.error('Hình ảnh phải nhỏ hơn 5MB!');
  }
  return isCheckTypeImage && isLt10M;
};
