import { FileTextOutlined } from '@ant-design/icons';
import moment, { MomentInput } from 'moment';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { Switch } from 'antd';

import './styles.scss';

export const renderStatus = (status: number) => {
  switch (status) {
    case 0:
      return {
        name: 'Chờ duyệt',
        color: '#0958d9',
        background: '#e6f4ff',
        borderColor: '#91caff',
      };
    case 1:
      return {
        name: 'Chờ duyệt',
        color: '#0958d9',
        background: '#e6f4ff',
        borderColor: '#91caff',
      };
    case 2:
      return {
        name: 'Phê duyệt',
        color: '#389e0d',
        background: '#f6ffed',
        borderColor: '#b7eb8f',
      };
    case 3:
      return {
        name: 'Đang xử lý',
        color: '#d48806',
        background: '#fffbe6',
        borderColor: '#ffe58f',
      };
    case 4:
      return {
        name: 'Đã hoàn thành',
        color: '#531dab',
        background: '#f9f0ff',
        borderColor: '#d3adf7',
      };
    case 5:
      return {
        name: 'Từ chối',
        color: '#ff4d4f',
        background: '#fff2f0',
        borderColor: '#ffccc7',
      };
    case 6:
      return {
        name: 'Hết hiệu lực',
        color: '#ff4d4f',
        background: '#fff2f0',
        borderColor: '#ffccc7',
      };

    default:
      break;
  }
};

const GetColumnsConfig: (
  handleShowDetail: (id: string) => void,
  onChangePublic?: (id: string, isPublic: boolean) => void,
  actionRef?: any
) => any[] = (handleShowDetail, onChangePublic, actionRef) => {
  const isMobile = useCheckMobileScreen();

  const columns: any = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 50,
      render: (_: any, __: any, indx: number) => {
        const current = actionRef?.current?.pageInfo?.current || 1;
        const size = actionRef?.current?.pageInfo?.pageSize || 10;
        return (
          <div style={{ marginTop: 8, marginBottom: 8 }}>
            {(current - 1) * size + indx + 1}
          </div>
        );
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      align: 'center',
      key: 'createdDate',
      render: (_: any, record: { createdDate: MomentInput }) => {
        return moment(record.createdDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Tên',
      dataIndex: 'title',
      align: 'center',
      key: 'title',
    },
    {
      title: 'Thể loại',
      dataIndex: 'categories',
      align: 'center',
      key: 'categories',
      render: (_: any, record: { categories: any[] }) => {
        return <div>{record?.categories?.join(', ')}</div>;
      },
    },

    {
      title: 'Cập nhật gần nhất',
      dataIndex: 'modifiedDate',
      align: 'center',
      key: 'modifiedDate',
      render: (_: any, record: { modifiedDate: MomentInput }) => {
        return moment(record.modifiedDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Public',
      dataIndex: 'isPublic',
      align: 'center',
      key: 'isPublic',
      width: 100,
      render: (_: any, record: { isPublic: boolean; id: string }) => {
        return (
          <Switch
            defaultChecked
            checked={record?.isPublic}
            onChange={(checked: boolean) =>
              onChangePublic?.(record?.id, checked)
            }
          />
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: 'actions',
      align: 'center',
      width: isMobile ? 80 : 150,
      render: (_: any, record: any) => {
        return (
          <div className="flex items-center justify-center">
            <div
              className="flex txtDetail"
              onClick={handleShowDetail?.bind(null, record?.id)}
            >
              <div>
                <FileTextOutlined />
              </div>
              <div style={{ marginLeft: 5 }}>
                {isMobile ? 'Chi tiết' : 'Xem chi tiết'}
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  return columns;
};

export default GetColumnsConfig;
