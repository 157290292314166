import { Form, Input, Modal, message } from 'antd';
import { useEffect, useState } from 'react';

import { RESPONSE_CODE } from 'src/utils/constants';
import { isNullOrEmpty } from 'src/utils/stringUtils';
import StoriesServices from '../../services/stories.service';

type Props = {
  open: boolean;
  closeModalAdd: () => void;
  id: string;
  callBack: () => void;
  nodeDetail: any;
  isNodeEnding: boolean;
  savePosition:(isShowNotification : boolean)=>void;
};
const { TextArea } = Input;

export const AddNodeModal = ({
  open,
  closeModalAdd,
  id,
  callBack,
  nodeDetail,
  isNodeEnding,
  savePosition
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    const data = {
      content: nodeDetail?.content,
      option1: nodeDetail?.options?.filter(
        (item: any) => item?.optionId === 1
      )?.[0]?.content,
      option2: nodeDetail?.options?.filter(
        (item: any) => item?.optionId === 2
      )?.[0]?.content,
    };

    form.resetFields();

    if (!isNullOrEmpty(nodeDetail)) {
      form.setFieldsValue(data);
    }
  }, [nodeDetail]);

  const onUpdate = async () => {
    form
      .validateFields()
      .then(async (value) => {
        setLoading(true);

        const params: any = {
          content: value?.content,
          options: [],
        };

        if (!isNullOrEmpty(value?.option1)) {
          params.options.push(value?.option1);
        }
        if (!isNullOrEmpty(value?.option2)) {
          params.options.push(value?.option2);
        }

        const res = await StoriesServices.updateNode(
          id,
          nodeDetail?.id,
          params
        );

        if (res?.status === RESPONSE_CODE?.SUCCESS) {
          setLoading(false);
          callBack();
          closeModalAdd();
          savePosition?.();
        } else {
          message.error(res?.error?.errorMessage ?? 'Có lỗi xảy ra');
        }
      })
      .catch(() => {});
  };

  const onOk = () => {
    form
      .validateFields()
      .then(async (value) => {
        setLoading(true);
        const params: any = {
          content: value?.content,
          positionX: -60,
          positionY: -60,
          options: [],
        };

        if (isNodeEnding) {
          params.ending = true;
        } else {
          if (!isNullOrEmpty(value?.option1)) {
            params.options.push(value?.option1);
          }
          if (!isNullOrEmpty(value?.option2)) {
            params.options.push(value?.option2);
          }
        }
        const res = await StoriesServices.addNode(id, params);
        if (res?.status === RESPONSE_CODE?.SUCCESS) {
          savePosition?.(false);
          setLoading(false);
          callBack();
          closeModalAdd();
          
        } else {
          setLoading(false);
          message.error(res?.error?.errorMessage ?? 'Có lỗi xảy ra');
        }
      })
      .catch(() => {});
  };

  const updateOption = async (optionId: string) => {
    const optContent = form.getFieldValue(
      optionId === '1' ? 'option1' : 'option2'
    );

    if (!isNullOrEmpty(nodeDetail?.id)) {
      const res = await StoriesServices.updateOption(
        id,
        nodeDetail?.id,
        optionId,
        {
          content: optContent,
        }
      );
      if (res?.status === RESPONSE_CODE?.SUCCESS) {
        callBack();
      } else {
        message.error(res?.error?.errorMessage ?? 'Có lỗi xảy ra');
      }
    }
  };

  if (isNullOrEmpty(id)) {
    return;
  }

  return (
    <Modal
      open={open}
      onOk={nodeDetail ? onUpdate : onOk}
      onCancel={closeModalAdd}
      okText={nodeDetail ? 'Sửa' : 'Thêm'}
      title={(isNodeEnding || nodeDetail?.ending) ? 'Kết truyện' : 'Đoạn câu truyện'}
      loading={loading}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Content"
          name="content"
          style={{ marginBottom: 10 }}
          rules={[{ required: true, message: 'Vui lòng thêm content!' }]}
          className="font-bold"
        >
          <TextArea placeholder={'Content...'} autoSize />
        </Form.Item>
        {!isNodeEnding && !nodeDetail?.ending && (
          <>
            <Form.Item
              label="Option 1"
              name="option1"
              style={{ marginBottom: 10 }}
              className="font-bold "
            >
              <TextArea
                placeholder={'Content...'}
                autoSize
                onBlur={updateOption?.bind(null, '1')}
              />
            </Form.Item>
            <Form.Item
              label="Option 2"
              name="option2"
              style={{ marginBottom: 10 }}
              className="font-bold "
            >
              <TextArea
                placeholder={'Content...'}
                autoSize
                onBlur={updateOption?.bind(null, '2')}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};
