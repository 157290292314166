import { getQueryString } from 'src/utils/stringUtils';
import APIService from '../utils/apiServices';

class StoriesServices extends APIService {
  getStories(params: { page: number; size: number }) {
    const url = getQueryString(`/admin/stories`, params);
    return this.request('GET', url);
  }

  getStoryDetail(id: string) {
    return this.request('GET', `/admin/stories/${id}`);
  }

  addNode(
    storyId: string,
    params: {
      content: string;
      options?: string[];
      positionX: number;
      positionY: number;
    }
  ) {
    return this.request('POST', `/admin/stories/${storyId}`, params);
  }

  updatePosition(
    storyId: string,
    params: {
      positionNodes: any[];
    }
  ) {
    return this.request('PATCH', `/admin/stories/${storyId}/position`, params);
  }

  updateConnection(params: {
    storyId: string;
    nodeId: string;
    optionId: number;
    nextNodeId: string;
  }) {
    return this.request(
      'PATCH',
      `/admin/stories/${params?.storyId}/nodes/${params?.nodeId}/options/${params?.optionId}/connect`,
      { nextNodeId: params?.nextNodeId }
    );
  }

  updateNode(
    storyId: string,
    nodeId: string,
    params: {
      options: string[];
      content: string;
    }
  ) {
    return this.request(
      'PATCH',
      `/admin/stories/${storyId}/nodes/${nodeId}`,
      params
    );
  }

  updateOption(
    storyId: string,
    nodeId: string,
    optionId: string,
    params: {
      content: string;
      nextNodeId?: string;
    }
  ) {
    return this.request(
      'PATCH',
      `/admin/stories/${storyId}/nodes/${nodeId}/options/${optionId}`,
      params
    );
  }

  async uploadFile(file: any) {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    return await this.request('POST', '/attachments/upload', bodyFormData, {
      headers: {
        'Content-Disposition': 'attachment',
        'Content-Type': 'application/json',
      },
    });
  }

  async addStory(params: {
    title: string;
    image?: string;
    description: string;
    categories: string[];
    content: string;
    options: string[];
  }) {
    return await this.request('POST', '/admin/stories', params);
  }

  editStory(
    id: string,
    params: {
      title?: string;
      image?: string;
      description?: string;
      categories?: string[];
      content?: string;
      options?: string[];
      isPublic?: boolean;
    }
  ) {
    return this.request('PATCH', `/admin/stories/${id}`, params);
  }
  deleteNode(storyId: string, params: { nodeId: string, optionId: number | string }) {
    return this.request('DELETE', `/admin/stories/${storyId}/nodes/${params.nodeId}/options/${params.optionId}`);
  }
}
const service = new StoriesServices();
export default service;
