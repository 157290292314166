import { APPLICATION_ACTION_TYPE } from "./action";
import { Action } from "./appContext";

export const reducer = (prevState: AppStorage, action: Action) => {
  switch (action.type) {
    case APPLICATION_ACTION_TYPE.SAVE_CATEGORY:
      return {
        ...prevState,
        categories: action.payload,
      };
    default:
      return prevState;
  }
};
