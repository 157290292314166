import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  LoginFormPage,
  ProCard,
  ProFormText,
} from "@ant-design/pro-components";
import { message } from "antd";
import React from "react";
import { RESPONSE_CODE } from "src/utils/constants";
import { LOCAL_STORAGE_VALUE } from "src/utils/enum";
import userServices from "../../services/users.service";
import LocalStorage from "../../utils/localStorage";

function Login() {
  // const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);

  const onFinish = async (values: any) => {
    setLoading(true);

    console.log("values", values);
    const resp = await userServices.login(values);
    console.log("resp", resp);
    const data = resp?.data;
    if (resp?.status === RESPONSE_CODE.SUCCESS) {
      LocalStorage.getInstance().save(LOCAL_STORAGE_VALUE.loginSession, data);
      setLoading(false);
      window.location.reload();
    } else {
      message.error(resp?.data?.message ?? "Có lỗi xảy ra");
      setLoading(false);
    }
  };

  return (
    <ProCard
      style={{
        backgroundColor: "white",
        height: "100vh",
      }}
    >
      <LoginFormPage
        backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
        title="LOGIN"
        submitter={{
          searchConfig: {
            submitText: "Đăng nhập",
          },
          submitButtonProps: { loading },
        }}
        onFinish={onFinish}
      >
        <div className="mt-5">
          <ProFormText
            name="username"
            fieldProps={{
              size: "large",
              prefix: <UserOutlined className={"prefixIcon"} />,
            }}
            placeholder="Tên đăng nhập"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập tên đăng nhập !",
              },
            ]}
          />
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: "large",
              prefix: <LockOutlined className={"prefixIcon"} />,
            }}
            placeholder={"Mật khẩu"}
            rules={[{ required: true, message: "Vui lòng nhập Mật khẩu!" }]}
          />
        </div>
      </LoginFormPage>
    </ProCard>
  );
}

export default Login;
