import { PlusOutlined } from "@ant-design/icons";
import { ProFormUploadButton } from "@ant-design/pro-components";
import { Modal, UploadFile } from "antd";
import { UploadProps } from "antd/lib/upload";
import { useEffect, useState } from "react";
import StoriesServices from "src/services/stories.service";
import { RESPONSE_CODE } from "src/utils/constants";
import { checkImageBeforeUpload } from "src/utils/helper";

type Props = {
  name: string;
  label: string;
  required?: boolean;
  url?: string;
  readOnly?: boolean;
  disabled?: boolean;
  onChangeImage?: (id: string) => void;
};

const UploadImage: React.FC<Props> = ({
  name,
  label,
  required,
  url,
  readOnly,
  disabled,
  onChangeImage,
}: Props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  console.log("previewImage", previewImage);

  useEffect(() => {
    if (url) {
      const image = url?.includes("https")
        ? url
        : process.env.REACT_APP_API_URL + "/attachments/" + url;
      setFileList([
        {
          uid: "1",
          name: "image.png",
          status: "done",
          url: image,
        },
      ]);
      setPreviewImage(image);
    }
  }, [url]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = () => {
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else {
      setFileList([{ ...file }]);
    }
  };

  const handlePostImage = async (options: any) => {
    const { onSuccess, onError } = options;
    try {
      const res = await StoriesServices.uploadFile(options.file);
      if (res?.status === RESPONSE_CODE.SUCCESS) {
        setPreviewImage(
          process.env.REACT_APP_API_URL + "/attachments/" + res?.data?.idStr
        );
        onChangeImage?.(res?.data?.idStr);
      }
      onSuccess("Ok");
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Someerror");
      onError({ err: error });
    }
  };

  return (
    <>
      <ProFormUploadButton
        name={name}
        label={label}
        max={0}
        rules={[
          {
            required: required,
            message: "Vui lòng tải hình ảnh lên",
          },
        ]}
        required={required}
        accept=".jpg, .jpeg, .png, .webp"
        fieldProps={{
          listType: "picture-card",
          fileList: fileList,
          onPreview: handlePreview,
          onChange: handleChange,
          beforeUpload: (file) => checkImageBeforeUpload(file),
          customRequest(options) {
            handlePostImage(options);
          },
          onRemove: () => !readOnly,
        }}
        icon={<PlusOutlined />}
        title={"Tải lên"}
        disabled={disabled}
      />
      <Modal
        open={previewOpen}
        footer={null}
        closable={false}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          id="img-preview"
          style={{ width: "100%" }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default UploadImage;
