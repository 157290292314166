export const LOCAL_STORAGE_VALUE = {
  loginSession: 'loginSession',
};

export interface DEPARTMENT_INFO_LIST {
  id?: number;
  name?: string;
  description?: string;
  email?: string;
  groups: {
    id?: number;
    name?: string;
    description?: string;
    email?: string;
  };
}

export type LEVEL_TYPE_STRING =
  | 'LEVEL_1'
  | 'LEVEL_2'
  | 'LEVEL_3'
  | 'LEVEL_4'
  | undefined;

export interface CREATE_DEPT {
  deptName: string;
  description: string;
}

export interface CREATE_GROUPS {
  deptName: string;
  parentDeptId: number;
  newLeader: string;
}

export interface EDIT_DEPT {
  deptName: string;
  description?: string;
  oldLeader: number;
  newLeader: number;
}

export interface SELECTED_GROUPS {
  deptDesc: string;
  deptId: number;
  deptName: string;
  groupMembers: any[];
  totalMember: number;
}

export interface DETAIL_MEMBER {
  active: number;
  fullName: string;
  id: number;
  saleManList: any;
  userName: string;
  jobTitle: string;
}

interface Account_info {
  userName: string;
  fullName: string;
  ipPhone: null | number;
}

export interface DETAIL_ACCOUNT_USER {
  accountInfo: Account_info;
  active: number;
  firstLogin: number;
  id: number;
  roles: string[];
  staffInfo: {
    deptInfos: any[];
    directManager: string;
    groupInfos: any[];
    jobTitle: string;
    level: string;
  };
}

export interface LIST_REASON {
  id: number;
  reason: string;
}

export interface LIST_REQUEST_TYPE {
  id: number;
  department: string;
  directManager: string;
  fullName: string;
  ipPhone: string;
  level: string;
  username: string;
}

export interface DATA_RECORD_REQUEST {
  canApprove: boolean;
  createdDate: string;
  endTime: string;
  leaveDays: number;
  reason: string;
  requestId: number;
  requestStatus: string;
  requestType: string;
  startTime: string;
  username: string;
}

export interface DATA_DETAIL_REQUEST_LEAVE_APP {
  createdDate: string;
  description: string;
  endTime: string;
  fullName: string;
  leaveDays: number;
  milestones: any[];
  reason: string;
  requestId: number;
  requestStatus: string;
  requestType: string;
  startTime: string;
  username: string;
  canApprove: boolean;
}

export interface DATA_RECORD_SELECTED_REQUEST_LEAVE_APP {
  canApprove: boolean;
  createdDate: string;
  endTime: string;
  leaveDays: number;
  reason: string;
  requestId: number;
  requestStatus: string;
  requestType: string;
  startTime: string;
  username: string;
}
