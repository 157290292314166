import { ConfigProvider } from "antd";
import viVN from "antd/es/locale/vi_VN";
import { Helmet } from "react-helmet";
import MyRoutes from "./Routes";
import { AppProvider } from "./context/appContext";
import "./styles/App.css";
import "./styles/main.scss";
import { LOCAL_STORAGE_VALUE } from "./utils/enum";
import LocalStore from "./utils/localStorage";
import { isNullOrEmpty } from "./utils/stringUtils";

const locale = viVN;

function App() {
  const loginSession = LocalStore.getInstance().read(
    LOCAL_STORAGE_VALUE.loginSession
  );

  return (
    <AppProvider>
      <Helmet encodeSpecialCharacters={true} titleTemplate="HRM - %s" />
      <ConfigProvider locale={locale}>
        {MyRoutes(!isNullOrEmpty(loginSession?.token))}
      </ConfigProvider>
    </AppProvider>
  );
}

export default App;
