import { ProTable } from "@ant-design/pro-components";
import { useNavigate } from "react-router-dom";
import useStories from "src/hooks/useStories";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_LIST } from "src/utils/constants";
import { useWindowSize } from "src/utils/customHooks";
import GetColumnsConfig from "./Columns";
import DetailStoryModal from "./component";
import { useContext } from "react";
import AppContext from "src/context/appContext";

export const HomeScreen = () => {
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);

  const { onPageChange, state, actionRef, onCloseDetail, onShowDetail, onChangePublic } =
    useStories();

  const onOpenDetailTicket = (id: string) => {
    // navigate(`/story/${id}`);
    onShowDetail(id);
  };

  const columns = GetColumnsConfig(onOpenDetailTicket, onChangePublic, actionRef);

  return (
    <div className="flex flex-col">
      <ProTable<any>
        scroll={{
          x: 1200,
          y: windowSize.height - 250,
        }}
        className={"custom-table"}
        rowKey="id"
        dataSource={state?.stories}
        loading={state?.loading}
        columns={columns}
        actionRef={actionRef}
        cardBordered
        editable={{
          type: "multiple",
        }}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
        }}
        search={{
          collapseRender: false,
          labelWidth: "auto",
        }}
        searchFormRender={() => {
          return (
            <div className="w-[100%] flex justify-end mb-[10px]">
              <div
                className="p-[10px] bg-[#0F67FE] text-[#fff] font-medium rounded-[5px] cursor-pointer"
                onClick={onShowDetail?.bind(null, undefined)}
              >
                Thêm truyện
              </div>
            </div>
          );
        }}
        options={false}
        pagination={{
          size: "small",
          showSizeChanger: true,
          current: state?.filter.page + 1,
          total: state?.metadata?.total,
          defaultPageSize: state?.filter.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => <div>Tổng số: {total.toLocaleString()}</div>,
        }}
        dateFormatter="string"
      />
      {state?.showDetail && (
        <DetailStoryModal
          onCloseModalAdd={onCloseDetail}
          id={state?.focusStory}
          reload={() => onPageChange(0, DEFAULT_PAGE_SIZE)}
        />
      )}
    </div>
  );
};
