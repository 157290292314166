import React, { useState, useEffect, useContext } from 'react';
import { DEFAULT_PAGE_SIZE, RESPONSE_CODE } from 'src/utils/constants';
import StoriesServices from '../../src/services/stories.service';
import userServices from '../../src/services/users.service';
import { message } from 'antd';
import AppContext from 'src/context/appContext';
import { APPLICATION_ACTION_TYPE } from 'src/context/action';

type Props = {
  loading: boolean;
  filter: {
    page: number;
    size: number;
  };
  metadata: any;
  stories: any[];
  isRefresh: boolean;
  showDetail: boolean;
  focusStory?: string;
};

const useStories = () => {
  const initialData = {
    loading: false,
    filter: {
      page: 0,
      size: DEFAULT_PAGE_SIZE,
    },
    metadata: {},
    stories: [],
    isRefresh: false,
    showDetail: false,
    focusStory: undefined,
  };
  const { dispatch } = useContext(AppContext);
  const actionRef = React.useRef<any>();
  const [state, setState] = useState<Props>(initialData);

  const getCategories = async () => {
    const res = await userServices.getCategories();
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      dispatch({
        type: APPLICATION_ACTION_TYPE.SAVE_CATEGORY,
        payload: res?.data,
      });
    }
  };

  useEffect(() => {
    if (state?.isRefresh) {
      getStories();
    }
  }, [state?.isRefresh]);

  useEffect(() => {
    getStories();
    getCategories();
  }, []);

  const getStories = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    const res = await StoriesServices.getStories(state?.filter);

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      const { data, page, size, total, totalPages } = res?.data;
      setState((prev) => ({
        ...prev,
        loading: false,
        stories: data,
        isRefresh: false,
        metadata: {
          totalPages: totalPages,
          total: total,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isRefresh: false,
        loading: false,
      }));
      message.error(res?.data ?? 'Có lỗi xảy ra!');
    }
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setState((prevState) => ({
      ...prevState,
      isRefresh: true,
      filterTickets: {
        ...prevState.filter,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const onShowDetail = (id?: string) => {
    setState((prevState) => ({
      ...prevState,
      showDetail: true,
      focusStory: id ?? undefined,
    }));
  };

  const onCloseDetail = () => {
    setState((prevState) => ({
      ...prevState,
      showDetail: false,
      focusStory: undefined,
    }));
  };

  const onChangePublic = async (id: string, isPublic: boolean) => {
    const res = await StoriesServices.editStory(id, { isPublic });
    if (res.status === RESPONSE_CODE.SUCCESS) {
      setState((prevState) => ({
        ...prevState,
        stories: state?.stories?.map((item) => {
          if (item?.id === id) {
            return {
              ...item,
              isPublic: isPublic,
            };
          }
          return item;
        }),
      }));
    } else {
      message.error(res?.data ?? 'Có lỗi xảy ra!');
    }
  };

  return {
    onPageChange,
    state,
    actionRef,
    onShowDetail,
    onCloseDetail,
    onChangePublic,
  };
};

export default useStories;
