import { ProConfigProvider, ProLayout } from "@ant-design/pro-components";
// import { HeaderViewProps } from '@ant-design/pro-layout/es/components/Header';
import { SiderMenuProps } from "@ant-design/pro-layout/es/components/SiderMenu/SiderMenu";
import { WithFalse } from "@ant-design/pro-layout/es/typing";
import { AvatarProps, ConfigProvider } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import {
  Outlet,
  unstable_HistoryRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AppContext from "src/context/appContext";
import useCheckMobileScreen from "src/hooks/useCheckMobileScreen";
import { ROUTERS } from "src/utils/constants";
import { LOCAL_STORAGE_VALUE } from "src/utils/enum";
import LocalStore from "src/utils/localStorage";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import defaultProps from "./_defaultProps";
import { Menu } from "./menu";
import { ReactComponent as IconLogOut } from "../../assets/IconLogOut.svg";
import LocalStorage from "../../utils/localStorage";

export const MainLayout = () => {
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();
  const { dispatch, appState } = React.useContext(AppContext);
  const [isOpenModalChangePassword, setIsOpenModalChangePassword] =
    React.useState<boolean>(false);
  const packageData = require("../../../package.json");
  const location = useLocation();
  const handleChangeModalChangePassword = () => {
    setIsOpenModalChangePassword(!isOpenModalChangePassword);
  };

  const getPageName = (key: string) => {
    switch (key) {
      // case ROUTERS.Dashboard.key:
      //   return "TRANG THÔNG TIN NỘI BỘ";
      // case ROUTERS.TimeKeepingHistory.key:
      //   return "LỊCH SỬ CHẤM CÔNG";
      // case ROUTERS.TimeKeepingSum.key:
      //   return "TỔNG HỢP GIỜ CÔNG";
      // case ROUTERS.ManagerTicket.key:
      //   return "QUẢN LÝ DUYỆT TICKET";
      // case ROUTERS.ListTicket.key:
      //   return "DANH SÁCH TICKET";
      // case ROUTERS.Organizational.key:
      //   return "DANH SÁCH NHÂN SỰ THEO BỘ PHẬN";
      // case ROUTERS.Accounts.key:
      //   return "DANH SÁCH NHÂN SỰ NGHỈ VIỆC";
      // case ROUTERS.DetailUser.key:
      //   return "THÔNG TIN CÁ NHÂN";
      // case ROUTERS.SocialNetwork.key:
      //   return "BẢNG TIN";
      default:
        return "";
    }
  };

  const getPageDescription = (key: string) => {
    switch (key) {
      // case ROUTERS.Organizational.key:
      //   return 'Tạo mới bộ phận, tạo các nhóm thuộc bộ phận và thêm các thành viên.';
      default:
        return null;
    }
  };

  const headerTitleRender = () => {
    const defaultDom = (
      <a style={{ marginRight: 120 }}>
        <Logo height={40} width={40} className="absolute left-[-5px]" />
      </a>
    );

    if (typeof window === "undefined") return defaultDom;
    if (document.body.clientWidth < 1400) {
      return defaultDom;
    }
    return <>{defaultDom}</>;
  };

  const pageName = () => {
    return (
      !isMobile &&
      getPageName(location?.pathname?.replaceAll("/", "")) && (
        <div style={{ marginLeft: collapsed ? 60 : 100 }}>
          <div
            className="text-neutral-500 not-italic font-bold"
            style={{ fontSize: 20, color: "#000000D9", lineHeight: 1 }}
          >
            {getPageName(location?.pathname?.replaceAll("/", ""))}
          </div>
          {getPageDescription(location?.pathname?.replaceAll("/", "")) && (
            <div style={{ fontSize: 14, lineHeight: 1, marginTop: 5 }}>
              {getPageDescription(location?.pathname?.replaceAll("/", ""))}
            </div>
          )}
        </div>
      )
    );
  };

  const onLogOut = () => {
    LocalStorage.getInstance().save(
      LOCAL_STORAGE_VALUE.loginSession,
      undefined
    );

    if (location?.pathname === "/admin") {
      window.location.reload();
    } else {
      navigate("/admin", { replace: true });
    }
  };

  const actionsRender = () => {
    return (
      <div onClick={onLogOut}>
        <IconLogOut height={20} width={20} class="mt-[15px]" />
      </div>
    );
  };

  return (
    <ProConfigProvider hashed={false} autoClearCache>
      <ConfigProvider
        getTargetContainer={() => {
          return document.getElementById("test-pro-layout") || document.body;
        }}
      >
        <ProLayout
          prefixCls="my-prefix"
          layout={"top"}
          fixSiderbar={false}
          siderMenuType="group"
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
          {...defaultProps}
          menu={{
            collapsedShowGroupTitle: true,
          }}
          // headerContentRender={pageName}
          headerTitleRender={headerTitleRender}
          style={{}}
          contentStyle={{ background: "none" }}
          actionsRender={false}
          headerRender={(props: SiderMenuProps) => {
            return (
              <div className="w-[100%] flex  items-center justify-between">
                <div className="flex gap-[50px] items-center">
                  <Logo height={40} width={40} className="mr-[0px]" />
                  {props?.menuData?.map((item) => {
                    return (
                      <div
                        className="flex cursor-pointer"
                        key={item?.path}
                        onClick={() => {
                          navigate(`/admin/${item.path}`);
                        }}
                      >
                        {item?.name}
                      </div>
                    );
                  })}
                </div>
                <div
                  onClick={onLogOut}
                  className="w-[60px] items-center flex flex-col cursor-pointer"
                >
                  <IconLogOut height={20} width={20} class="" />
                </div>
              </div>
            );
          }}
        >
          <Outlet />
        </ProLayout>
      </ConfigProvider>
    </ProConfigProvider>
  );
};
