import { BaseOptionType, DefaultOptionType } from "antd/lib/select";

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  LOCKED: "LOCKED",
  BANNED: "BANNED",
  INACTIVE: "INACTIVE",
};

export const ROUTERS = {
  Home: {
    key: "",
    path: "/",
  },
  Tools: {
    key: "",
    path: "/tools",
  },
};

export const ROLES = {
  ROLE_ADMIN: "LEVEL_4",
  ROLE_MANAGER: "LEVEL_3",
  ROLE_LEADER: "LEVEL_2",
  ROLE_MAN: "LEVEL_1",

  ROLE_SALE_LEADER: "ROLE_SALE_LEADER",
  ROLE_SALE_MAN: "ROLE_SALE_MAN",
  ROLE_SALE_MANAGER: "ROLE_SALE_MANAGER",
  ROLE_SALE_ADMIN: "ROLE_SALE_ADMIN",

  ROLE_ACCOUNTANT: "ROLE_ACCOUNTANT",

  ROLE_PRODUCT_LEADER: "ROLE_PRODUCT_LEADER",
  ROLE_PRODUCT_MAN: "ROLE_PRODUCT_MAN",
  ROLE_PRODUCT_MANAGER: "ROLE_PRODUCT_MANAGER",

  ROLE_MKT_LEADER: "ROLE_MKT_LEADER",
  ROLE_MKT_MAN: "ROLE_MKT_MAN",
  ROLE_MKT_MANAGER: "ROLE_MKT_MANAGER",

  ROLE_CC_LEADER: "ROLE_CC_LEADER",
  ROLE_CC_MAN: "ROLE_CC_MAN",
  ROLE_CC_MANAGER: "ROLE_CC_MANAGER",

  ROLE_BO_LEADER: "ROLE_BO_LEADER",
  ROLE_BO_MAN: "ROLE_BO_MAN",
  ROLE_BO_MANAGER: "ROLE_BO_MANAGER",
};

export const GLOBAL_HEIGHT = 64 + 56 + 48;

export const PAGE_SIZE_LIST = [10, 20, 50, 100, 200];

export const DEFAULT_PAGE_SIZE = 50;

export const REGEX_PASSWORD = /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Za-z]).*/g;
export const REGEX_USERNAME = /^([a-zA-Z0-9_]+)$/g;

export const REGEX_PHONE_NUMBER = /^[1-9]([0-9]+){8}$/g;

export const getRoleName = (name?: string) => {
  switch (name) {
    case ROLES.ROLE_ADMIN:
      return "Admin";
    case ROLES.ROLE_MANAGER:
      return "Quản lý";
    case ROLES.ROLE_LEADER:
      return "Trưởng nhóm";
    case ROLES.ROLE_MAN:
      return "Nhân viên";
  }
};

export const LEVEL_LIST: (DefaultOptionType | BaseOptionType)[] = [
  { label: "Level 1 - Nhân viên", value: "LEVEL_1" },
  { label: "Level 2 - Trưởng nhóm", value: "LEVEL_2" },
  { label: "Level 3 - Quản lý", value: "LEVEL_3" },
  { label: "Level 4 - Admin", value: "LEVEL_4" },
];

export const GENDERS: (DefaultOptionType | BaseOptionType)[] = [
  { label: "Nam", value: 0 },
  { label: "Nữ", value: 1 },
];

export const STATUS_REQUEST: (DefaultOptionType | BaseOptionType)[] = [
  { label: "Chờ duyệt", value: "pending_approve" },
  { label: "Đang duyệt", value: "approving" },
  { label: "Đã duyệt", value: "approved" },
  { label: "Bị từ chối", value: "rejected" },
];

export const DEFAULT_FORMAT_DATE_TIME = "DD/MM/YYYY HH:mm";
export const DEFAULT_FORMAT_DATE = "DD/MM/YYYY";

export enum PERMISSION {
  LEVEL_1 = "LEVEL_1",
  LEVEL_2 = "LEVEL_2",
  LEVEL_3 = "LEVEL_3",
  LEVEL_4 = "LEVEL_4",
}

export enum OPTIONS_PERMISSIONS {
  READ = "readPermission",
  UPDATE = "updatePermission",
  DELETE = "deletePermission",
  OTHER = "otherPermission",
}

export enum PERMISSION_TYPE {
  DASHBOARD = "dashboard",
  ORGANIZATIONAL = "organizational",
  STAFFS = "staffs",
  PERSONNEL_LEAVE = "personnel_leave",
  TIMEKEEPING_HISTORY = "timekeeping_history",
  TIMEKEEPING_SYNTHESIZE = "timekeeping_synthesize",
  TICKETS = "tickets",
  RECEIVE_PROCESS = "receive_process",
  PROJECTS = "projects",
  TASKS = "tasks",
  BUGS = "bugs",
}

export const TICKET: ticketConstantProps[] = [
  {
    id: 1,
    label: "Đơn xin nghỉ phép",
  },
  {
    id: 2,
    label: "Đơn giải trình chấm công",
  },
];

export const TICKET_TYPE: ticketConstantProps[] = [
  {
    id: 1,
    label: "Nghỉ phép năm",
  },
  {
    id: 2,
    label: "Nghỉ không lương",
  },
  {
    id: 3,
    label: "Kết hôn (bản thân)",
  },
  {
    id: 4,
    label: "Con kết hôn",
  },
  {
    id: 5,
    label: "Tang (tứ thân phụ mẫu, vợ, chồng, con)",
  },
];

export const TICKET_REASON: ticketConstantProps[] = [
  {
    id: 1,
    label: "Bận việc gia đình",
  },
  {
    id: 2,
    label: "Lý do sức khỏe cá nhân",
  },
  {
    id: 3,
    label: "Nghỉ mát",
  },
  {
    id: 4,
    label: "Nghỉ khám thai",
  },

  {
    id: 5,
    label: "Nghỉ kết hôn",
  },
  {
    id: 6,
    label: "Nghỉ tang",
  },
  {
    id: 7,
    label: "Nghỉ chăm con ốm",
  },
  {
    id: 8,
    label: "Nghỉ con kết hôn",
  },
];

export const EXPLANATION_DETAIL: ticketConstantProps[] = [
  {
    id: 1,
    label: "Quên chấm công",
  },
  {
    id: 2,
    label: "Máy chấm công hỏng",
  },
  {
    id: 3,
    label: "Làm việc ở ngoài",
  },
];

export const TICKET_TIMELINE = {
  morningStartTime: "08:00",
  morningEndTime: "12:00",
  afternoonStartTime: "13:30",
  afternoonEndTime: "17:30",
};

export const TICKET_STATUS: ticketConstantProps[] = [
  {
    id: 0,
    label: "Đã tạo",
  },
  {
    id: 1,
    label: "Chờ duyệt",
  },
  {
    id: 2,
    label: "Đã duyệt",
  },
  {
    id: 5,
    label: "Bị từ chối",
  },
  {
    id: 6,
    label: "Hết hiệu lực",
  },
];

export const TASK_STATUS: ticketConstantProps[] = [
  {
    id: 0,
    label: "Open",
  },
  {
    id: 1,
    label: "In progress",
  },
  {
    id: 2,
    label: "Resolved",
  },
  {
    id: 3,
    label: "Closed",
  },
  {
    id: 4,
    label: "Reopen",
  },
  {
    id: 5,
    label: "Canceled",
  },
  {
    id: 6,
    label: "Pending",
  },
];

export const PROJECT_STATUS: ticketConstantProps[] = [
  {
    id: 1,
    label: "In progress",
  },
  {
    id: 3,
    label: "Closed",
  },
];

export const ACCOUNT_STATUS: any[] = [
  {
    id: 0,
    label: "Ngừng hoạt động",
  },
  {
    id: 1,
    label: "Đang hoạt động",
  },
];

export const PERMISSION_STATUS: any[] = [
  {
    id: 0,
    label: "Ngừng hoạt động",
  },
  {
    id: 1,
    label: "Đang hoạt động",
  },
];

export const STATUS_TIMEKEEPING = [
  {
    value: "late",
    label: "Đi muộn",
  },
  {
    value: "early",
    label: "Về sớm",
  },
  {
    value: "out",
    label: "Quên chấm công",
  },
  {
    value: "",
    label: "Tất cả",
  },
];

export const CONTRACT_TYPE = [
  {
    value: "PROBATION",
    label: "Hợp đồng thử việc",
  },
  {
    value: "OFFICIAL",
    label: "Hợp đồng chính thức",
  },
  {
    value: "SERVICE",
    label: "Hợp đồng dịch vụ",
  },
];

export const TIME_WORKING = [
  {
    value: true,
    label: "Full-Time",
  },
  {
    value: false,
    label: "Part-Time",
  },
];

export const PRIORITY_VALUE = ["Highest", "High", "Medium", "Low"];

export enum RESPONSE_CODE {
  SUCCESS = 200,
  CREATED_SUCCESS = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,

  CODE_DEFAULT = -200,
  TIME_OUT = 45000,
  SERVER_ERROR = 502,
  CODE_TIME_OUT = 408,
  FORCE_UPDATE_APP = 423,
  CODE_TIMEOUT = "ECONNABORTED",
}

export enum FILE_TYPE {
  VIDEO = "video",
  IMAGE = "image",
  AUDIO = "music",
}
