import { LockOutlined } from '@ant-design/icons';
import {
  LoginFormPage,
  ProCard,
  ProFormText,
} from '@ant-design/pro-components';
import { message } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import userServices from '../../services/users.service';
import { REGEX_PASSWORD } from '../../utils/constants';
import LocalStorage from '../../utils/localStorage';

function ChangePassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    const resp = await userServices.changePassword(values);
    if (resp?.status === 200) {
      setLoading(false);
      message.success('Thay đổi mật khẩu thành công, Vui lòng đăng nhập lại !');
      LocalStorage.getInstance().save('loginSession', null);
      navigate('/admin');
    } else {
      message?.error(resp?.data?.message);
      setLoading(false);
    }
  };

  return (
    <ProCard
      style={{
        backgroundColor: 'white',
        height: '100vh',
      }}
    >
      <LoginFormPage
        backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
        title="Đổi mật khẩu"
        submitter={{
          searchConfig: {
            submitText: 'Tiếp tục',
          },
          submitButtonProps: { loading },
        }}
        autoComplete="off"
        onFinish={onFinish}
        size={'large'}
      >
        <div className="mt-5">
          <ProFormText.Password
            name="oldPassword"
            validateTrigger={'onBlur'}
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Nhập mật khẩu cũ'}
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu cũ!' }]}
          />
          <ProFormText.Password
            name="newPassword"
            validateTrigger={'onBlur'}
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Nhập mật khẩu mới'}
            rules={[
              {
                required: true,
                pattern: new RegExp(REGEX_PASSWORD),
                message:
                  'Mật khẩu mới có ít nhất 8 ký tự bao gồm: tối thiểu 1 chữ cái, 1 số.',
              },
            ]}
          />
          <ProFormText.Password
            name="confirmNewPassword"
            validateTrigger={'onBlur'}
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Nhập lại mật khẩu mới'}
            rules={[
              { required: true, message: 'Vui lòng nhập lại mật khẩu mới!' },
            ]}
          />
        </div>
      </LoginFormPage>
    </ProCard>
  );
}

export default ChangePassword;
