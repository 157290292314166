import { getQueryString } from "src/utils/stringUtils";
import APIService from "../utils/apiServices";

class UserServices extends APIService {
  async login(data: {} | undefined) {
    return await this.request("POST", `/admin/auth/login`, data);
  }

  importEmployeeInfo(file) {
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);
    return this.request("POST", "account/import", bodyFormData, {
      headers: {
        "Content-Disposition": "attachment",
        "Content-Type": "multipart/form-data",
      },
    });
  }

  exportEmployeeInfo = () => {
    return this.request(
      "GET",
      "account/export",
      {},
      {
        responseType: "blob",
      }
    );
  };

  async confirmQuitJobs(params, userId) {
    return await this.request(
      "PUT",
      `/account/v1_5/${userId}/confirm-quit-of-job`,
      params
    );
  }

  async getStories(params: { page: number; size: number }) {
    console.log("params", params);
    return await this.request("GET", `/v1/stories`, params);
  }

  async getCategories() {
    return await this.request("GET", `v1/categories`);
  }
}
const service = new UserServices();
export default service;
