import {
  Background,
  Controls,
  Handle,
  Panel,
  Position,
  ReactFlow,
  ReactFlowProvider,
  useReactFlow,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { message, Modal, Popover } from 'antd';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AddNodeModal } from 'src/components/AddNodeModal';
import useStoryDetail from 'src/hooks/useStoryDetail';
import './styles.scss';
import { ReactComponent as IconBack } from '../../assets/iconBackCircle.svg';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Dagre from '@dagrejs/dagre';
import '@xyflow/react/dist/style.css';

const StoryDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { fitView } = useReactFlow();
  const {
    state,
    onNodesChange,
    nodes,
    edges,
    onConnect,
    onEdgesChange,
    closeModal,
    showModal,
    showModalAdd,
    closeModalAdd,
    getStoryDetail,
    onSavePosition,
    onCreateConnect,
    onFocusNode,
    setNodes,
    setEdges,
    onDeleteNode,
    setIsChangeDirection
  } = useStoryDetail(id);

  const renderModalAdd = useCallback(() => {
    return (
      <AddNodeModal
        id={id ?? ''}
        open={state?.isModalOpenAdd}
        closeModalAdd={closeModalAdd}
        callBack={getStoryDetail}
        nodeDetail={state?.focusNode}
        isNodeEnding={state?.isNodeEnding}
        savePosition={onSavePosition}
      />
    );
  }, [state?.isModalOpenAdd, state?.focusNode]);

  const TextUpdaterNode = ({ data, onClick }: any) => {
    const onChange = useCallback((value: any) => {
      onFocusNode(value?.id);
    }, []);

    const isHaveNode1 = data?.item?.options?.filter(
      (item: any) => item?.optionId === 1
    )?.[0];
    const isHaveNode2 = data?.item?.options?.filter(
      (item: any) => item?.optionId === 2
    )?.[0];


    return (
      <>
        {data?.value !== 1 && (
          <Handle type="target" position={Position.Left} id="a" />
        )}
        <Popover
          content={
            <div className="max-w-[700px]">
              <p className="mb-[10px] font-bold">{data?.item?.content}</p>
              <div className="flex items-center mb-[5px]">
                <div className="h-[10px] w-[10px] bg-[red] rounded-[5px] mr-[5px]" />
                <p>
                  {
                    data?.item?.options?.filter(
                      (item: any) => item?.optionId === 1
                    )?.[0]?.content
                  }
                </p>
              </div>
              <div className="flex items-center mb-[5px]">
                <div className="h-[10px] w-[10px] bg-[green] rounded-[5px] mr-[5px]" />
                <p>
                  {
                    data?.item?.options?.filter(
                      (item: any) => item?.optionId === 2
                    )?.[0]?.content
                  }
                </p>
              </div>
            </div>
          }
        >
          <div
            className="h-[20px] w-[40px] bg-[#fff] flex items-center justify-center rounded-[5px] border-solid border-1 border-[#000] text-[10px]"
            onClick={onChange?.bind(null, data?.item)}
          >
            {data?.value}
          </div>
        </Popover>
        {isHaveNode1 && (<Handle
          type="source"
          position={Position.Right}
          id="1"
          style={{ background: 'red', top: 0 }}
        />)}
       {isHaveNode2 && ( <Handle
          type="source"
          position={Position.Right}
          id="2"
          style={{ background: 'green', top: 20 }}
        />)}
      </>
    );
  };

  const getLayoutElements = (nodes: any, edges: any, options: any) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({ rankdir: options.direction });

    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) =>
      g.setNode(node.id, {
        ...node,
        width: node.measured?.width ?? 0,
        height: node.measured?.height ?? 0,
      })
    );

    Dagre.layout(g);

    return {
      nodes: nodes.map((node) => {
        const position = g.node(node.id);
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        const x = position.x - (node.measured?.width ?? 0) / 2;
        const y = position.y - (node.measured?.height ?? 0) / 2;

        return { ...node, position: { x, y } };
      }),
      edges,
    };
  };

  const onLayout = useCallback(
     (direction) => {
      console.log(nodes);
      const layouted = getLayoutElements(nodes, edges, { direction });

      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);

      window.requestAnimationFrame(() => {
        fitView();
      });
      setIsChangeDirection(true);

    },
    [nodes, edges]
  );

  const goBack = () => {
    navigate(-1);
  };

  const nodeTypes = { textUpdater: TextUpdaterNode };
  return (
    <div className="text-[#000] h-[100vh] w-[100%]">
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onDelete={onDeleteNode}
        // onNodeDragStop={() => setTimeout(onSavePosition, 1000)}
        fitView
        nodeTypes={nodeTypes}
      >
        <div className="flex absolute right-[0px] left-[0px]  items-center z-10 w-[300px]">
          <div className="font-bold text-[16px] mb-[10px] flex items-center gap-[10px]">
            <div onClick={goBack} className="cursor-pointer">
              <IconBack className="h-[25px] w-[25px]" />
            </div>
            {state?.detail?.title}
          </div>
        </div>
        <Background />
        <Controls />
        <Panel position="top-right" className="mt-2">
          <button
            onClick={showModalAdd.bind(null, false)}
            className="bg-[#0F67FE] p-[5px] text-[#fff] rounded-[5px] cursor-pointer"
          >
            Add node
          </button>
          <button
            onClick={showModalAdd.bind(null, true)}
            className="bg-[#0F67FE] p-[5px] text-[#fff] rounded-[5px] cursor-pointer ml-[5px]"
          >
            Add Ending
          </button>
          <button
            onClick={() => onLayout('TB')}
            className="bg-[#0F67FE] p-[5px] text-[#fff] rounded-[5px] cursor-pointer ml-[5px]"
          >
            Vertical
          </button>
          <button
            onClick={() => onLayout('LR')}
            className="bg-[#0F67FE] p-[5px] text-[#fff] rounded-[5px] cursor-pointer ml-[5px]"
          >
            Horizontal
          </button>
          <button
            onClick={onSavePosition.bind(null, true)}
            className="bg-[#0F67FE] p-[5px] text-[#fff] rounded-[5px] cursor-pointer ml-[5px]"
          >
            Save
          </button>
        </Panel>
      </ReactFlow>
      <Modal
        title="Bạn có chắc chắn muốn thay đổi không ?"
        open={state?.isModalOpen}
        onOk={onCreateConnect}
        onCancel={closeModal}
        loading={state?.loading}
      ></Modal>
      {state?.isModalOpenAdd && renderModalAdd()}
    </div>
  );
};

function StoryDetailScreen(props: any) {
  return (
    <ReactFlowProvider>
      <StoryDetail {...props} />
    </ReactFlowProvider>
  );
}

export default StoryDetailScreen;
