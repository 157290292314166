import isArray from 'lodash/isArray';
import LocalStore from './localStorage';
import moment from 'moment';
import { OPTIONS_PERMISSIONS } from './constants';

export type ObjectType = { [key: string]: any | any[] };

export function getQueryString(
  requestUrl: string,
  params?: ObjectType
): string {
  if (typeof params === 'object' && params) {
    const ret: string[] = [];
    for (const d of Object.keys(params)) {
      if (isArray(params[d])) {
        const arrayParams: string = `${d}=${params[d].join(',')}`;
        ret.push(arrayParams);
      } else {
        ret.push(
          d +
            '=' +
            encodeURIComponent(params[d])
              .replace(/%20/g, '+')
              .replace(/%3A/g, ':')
        );
      }
    }
    if (ret.length > 0) {
      const retString = ret.join('&');
      if (requestUrl.includes('?')) {
        requestUrl = requestUrl + '&' + retString;
      } else {
        requestUrl = requestUrl + '?' + retString;
      }
    }
  }
  return requestUrl;
}

/**
 * convert object to request URL
 * @param requestUrl: string url
 * @param params: object params
 * @param queryString: object query string
 */
export function getRequestUrl(
  requestUrl: string,
  params?: GetRequestParams,
  queryString?: ObjectType
): string {
  if (typeof params === 'object' && params) {
    if (params?.parentId) {
      requestUrl += `/${params.parentId}`;
    }
    if (params?.partial) {
      requestUrl += `/${params.partial}`;
    }
    if (params?.subId) {
      requestUrl += `/${params.subId}`;
    }
    if (params?.action) {
      requestUrl += `/${params.action}`;
    }
  }
  if (queryString && !isNullOrEmpty(queryString)) {
    return getQueryString(requestUrl, queryString);
  }
  return requestUrl;
}

/**
 * thêm mới hoặc sửa lại param của query string
 * @param uri
 * @param key
 * @param value
 * @returns {*}
 */
export function updateQueryStringParameter(uri, key, value) {
  if (uri === undefined || uri === null) return '';
  let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  let separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (value === null) {
    return uri.replace(re, '$1' + '$2');
  }
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

export function arrStringToString(arr, char?: string) {
  if (Array.isArray(arr)) {
    let text = '';
    for (let i = 0; i < arr.length; i++) {
      text = text + arr[i];
      if (i < arr.length - 1) {
        text = text + `${char || ','} `;
      }
    }
    return text;
  }

  return '';
}

export const hasAnyPermission = (
  code: string | undefined,
  permissionTypes: string
): boolean => {
  const user = LocalStore.getInstance().read('currentLoggedUser');

  if (!user || !user.staffInfo) {
    return false;
  }

  if (user.staffInfo.level === 'LEVEL_4') {
    return true;
  }

  const permission = user.permissions.find((p: any) => p.code === code);

  const hasPermission = (permissionType: string): boolean => {
    if (!permission) {
      return false;
    }

    let permissionValue = 0;

    switch (permissionType) {
      case OPTIONS_PERMISSIONS.READ:
        permissionValue = permission.readPermission;
        break;
      case OPTIONS_PERMISSIONS.UPDATE:
        permissionValue = permission.updatePermission;
        break;
      case OPTIONS_PERMISSIONS.DELETE:
        permissionValue = permission.deletePermission;
        break;
      case OPTIONS_PERMISSIONS.OTHER:
        permissionValue = permission.otherPermission;
        break;
      default:
        return false;
    }

    return permissionValue === 1;
  };

  const hasCodePermission = hasPermission(permissionTypes);

  return hasCodePermission;
};

export const isUserHaveOneOfPermissions = (permissions: string[]): boolean => {
  const userRole = LocalStore.getInstance().read('currentLoggedUser');
  return permissions.includes(userRole?.staffInfo?.level ?? '');
};

export function isNullOrEmpty(data: any | any[]): boolean {
  if (!data) {
    return true;
  }
  if (data instanceof Array) {
    return data.length === 0;
  }
  if (typeof data === 'number') {
    return data === 0;
  }
  if (typeof data === 'undefined') {
    return true;
  }
  if (typeof data === 'object') {
    return Object.keys(data).length === 0;
  }
  let output = data;
  if (typeof output !== 'string') {
    output = output.toString();
  }
  output = output.trim();

  return output.length <= 0;
}

export function deleteParamsNotUsing(params: any) {
  for (const key in params) {
    if (isNullOrEmpty(params[key]) && params[key] !== 0) {
      delete params[key];
    }
  }
}

export const formatDate = (dateString: Date | string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const formatTimeAgo = (createdAt: any): string => {
  const createdMoment = moment(createdAt);
  const now = moment();
  const duration = moment.duration(now.diff(createdMoment));

  const daysAgo = duration.asDays();
  const hoursAgo = duration.asHours();
  const minutesAgo = duration.asMinutes();
  const secondsAgo = duration.asSeconds();

  if (daysAgo >= 1) {
    return `${Math.floor(daysAgo)} ngày trước`;
  } else if (hoursAgo >= 1) {
    return `${Math.floor(hoursAgo)} giờ trước`;
  } else if (minutesAgo >= 1) {
    return `${Math.floor(minutesAgo)} phút trước`;
  } else if (secondsAgo >= 0) {
    return `${Math.floor(secondsAgo)} giây trước`;
  } else {
    return 'Vừa xong';
  }
};

export const extractLinks = (text: string): string[] => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.match(urlRegex) || [];
};

export const formatNumber = (number: number = 0) => {
  let str = number?.toString();
  if (!str.includes('.')) {
    str += '.0';
  }
  return str;
};
