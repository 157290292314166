import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MainLayout } from "./components/ProLayout";
import Login from "./containers/Login";
import ChangePassword from "./containers/Login/ChangePassword";
import Page404 from "./containers/Page404";
import { HomeScreen } from "./containers/Home/Index";
import StoryDetailScreen from "./containers/StoryDetailScreen";

const MyRoutes = (isSignIn: boolean) => {
  return (
    <BrowserRouter>
      <Routes>
        {!isSignIn ? (
          <Route>
            <Route path="/admin" element={<Login />} />
            <Route path="/admin/change-password" element={<ChangePassword />} />
            <Route path="*" element={<Page404 />} />
          </Route>
        ) : (
          <Route path="/admin" element={<MainLayout />}>
            <Route path="/admin" element={<HomeScreen />} />
            <Route path="/admin/story/:id" element={<StoryDetailScreen />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
};
export default MyRoutes;
