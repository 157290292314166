import { LOCAL_STORAGE_VALUE } from './enum';
import LocalStorage from './localStorage';

const axios = require('axios').default;
const defaultLang = 'vi';
class APIService {
  instance: any;
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
  }

  async request(method, path, data = {}, config = {}) {
    this.handleRequestHeader();
    try {
      return await this.instance.request({
        method,
        url: path,
        data,
        ...config,
      });
    } catch (error) {
      return this.handleResponseError(error);
    }
  }

  async upload(method, path, data = {}, config = {}) {
    this.handleRequestHeader(true);
    try {
      return await this.instance.request({
        method,
        url: path,
        data,
        ...config,
      });
    } catch (error) {
      return this.handleResponseError(error);
    }
  }

  handleRequestHeader(isUpload = false) {
    const loginSession = LocalStorage.getInstance().read(
      LOCAL_STORAGE_VALUE.loginSession
    );

    this.instance.defaults.headers.common['Accept-Language'] = defaultLang;
    if (isUpload) {
      this.instance.defaults.headers.common['Accept'] = `application/json`;
      this.instance.defaults.headers.common[
        'Content-Type'
      ] = `multipart/form-data`;
    } else {
      this.instance.defaults.headers.common['Accept'] = `application/json`;
      this.instance.defaults.headers.common[
        'Content-Type'
      ] = `application/json;charset=UTF-8`;
    }

    if (loginSession) {
      this.instance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${loginSession?.token}`;
    }
  }

  handleResponseError({ response, request, message }) {
    // Server trả về lỗi không thành công
    if (response) {
      this.handleLoginState(response);
      return response;
    } else {
      // Reponse dont have, token is faild
      // comment when request_timeout data.
      // this.store.clearData();
      // window.location.reload();
    }

    // Không nhận được phản hồi từ phía server
    if (request) {
      return { status: 400, data: {}, message: 'Thất bại' };
    }

    return { code: '999', message };
  }

  // xóa loginSession và return về màn login
  handleLoginState(response) {
    if (response.status === 401) {
      const pathname = window.location.pathname;
      if (pathname) {
        LocalStorage.getInstance().save('redirectUrl', pathname);
      }
      LocalStorage.getInstance().save('loginSession', null);
      window.location.href = '/';
      // window.location.reload();
    }
  }
}

export default APIService;
