import { Button, Drawer, Form, Input, Select, Spin, message } from "antd";
import React, { useContext, useEffect } from "react";
import UploadImage from "src/components/UploadImage";
import AppContext from "src/context/appContext";
import useCheckMobileScreen from "src/hooks/useCheckMobileScreen";
import { useWindowSize } from "src/utils/customHooks";
import StoriesServices from "src/services/stories.service";
import { RESPONSE_CODE } from "src/utils/constants";
import { isNullOrEmpty } from "src/utils/stringUtils";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

type Props = {
  onCloseModalAdd: () => void;
  id?: string;
  reload: () => void;
};
const { TextArea } = Input;

const DetailStoryModal = ({ onCloseModalAdd, id, reload }: Props) => {
  const [initialValues, setInitialValues] = React.useState<any>(undefined);
  const [state, setState] = React.useState<any>({});
  const { appState } = useContext(AppContext);
  const [form] = Form.useForm();
  const isMobile = useCheckMobileScreen();
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const onChangeImage = (idstr?: string) => {
    setState((prev) => ({
      ...prev,
      image: idstr,
    }));
  };

  const onWatchAll = () => {
    navigate(`/admin/story/${state?.detail?.id}`);
  };

  const getStoryDetail = async () => {
    if (isNullOrEmpty(id)) {
      return;
    }
    setState((prev) => ({
      ...prev,
      detailLoading: true,
    }));
    const res = await StoriesServices.getStoryDetail(id ?? "");

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        detailLoading: false,
        detail: res?.data,
        image: res?.data?.image,
      }));

      const data = {
        title: res?.data?.title,
        description: res?.data?.description,
        content: res?.data?.storyNodes?.[0]?.content,
        option1: res?.data?.options?.filter(
          (item: any) => item?.optionId === 1
        )?.[0]?.content,
        option2: res?.data?.options?.filter(
          (item: any) => item?.optionId === 2
        )?.[0]?.content,
        categories: res?.data?.categories,
      };

      form.resetFields();

      form.setFieldsValue(data);
    } else {
      message.error(res?.error?.errorMessage ?? "Có lỗi xảy ra");
      setState((prev) => ({
        ...prev,
        detailLoading: false,
      }));
    }
  };

  useEffect(() => {
    if (!isNullOrEmpty(id)) {
      getStoryDetail();
    }
  }, [id]);

  console.log("state", state?.detail);

  const addStory = async (params: any) => {
    setState((prev: any) => ({
      ...prev,
      loading: true,
    }));
    const res = await StoriesServices.addStory(params);

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev: any) => ({
        ...prev,
        loading: false,
      }));
      message.success("Thêm thành công");
      reload();
      onCloseModalAdd();
    } else {
      setState((prev: any) => ({
        ...prev,
        loading: false,
      }));
      message.error(res?.data?.errorMessage ?? "Có lỗi xảy ra.");
    }
  };

  const editStory = async (params: any) => {
    if (params?.image?.includes("https")) {
      params.image = undefined;
      params.options = undefined;
    }
    setState((prev: any) => ({
      ...prev,
      loading: true,
    }));
    const res = await StoriesServices.editStory(state?.detail?.id, params);

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev: any) => ({
        ...prev,
        loading: false,
      }));
      message.success("Thành công");
      reload();
    } else {
      setState((prev: any) => ({
        ...prev,
        loading: false,
      }));
      message.error(res?.data?.errorMessage ?? "Có lỗi xảy ra.");
    }
  };

  const onSubmit = async (values: any) => {
    form
      .validateFields()
      .then(async (value) => {
        const params = {
          title: value?.title,
          image: state?.image,
          description: value?.description,
          categories: value?.categories,
          content: value?.content,
          options: [value?.option1, value?.option2],
        };

        isNullOrEmpty(state?.detail) ? addStory(params) : editStory(params);
      })
      .catch(() => {});
  };

  return (
    <Drawer
      onClose={onCloseModalAdd}
      open={true}
      width={isMobile ? windowSize.width : 600}
      closable={isMobile}
      title={
        <div className="flex justify-center items-center">Thêm truyện</div>
      }
      footer={
        <div className="flex justify-end gap-[10px]">
          {!isNullOrEmpty(state?.detail) && (
            <Button
              type="primary"
              onClick={onWatchAll}
              loading={state?.loading}
            >
              Xem tất cả
            </Button>
          )}
          <Button type="primary" onClick={onSubmit} loading={state?.loading}>
            {isNullOrEmpty(state?.detail) ? "Thêm" : "Sửa"}
          </Button>
        </div>
      }
    >
      {state?.detailLoading ? (
        <div className="flex items-center justify-center h-full mt-16">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          // onFinish={onResubmit}
          initialValues={initialValues}
          style={{ paddingBottom: 200 }}
        >
          <Form.Item
            label="Title"
            name="title"
            style={{ marginBottom: 10 }}
            rules={[{ required: true, message: "Vui lòng thêm title!" }]}
          >
            <TextArea placeholder={"Title..."} autoSize />
          </Form.Item>
          <Form.Item
            label="Mô tả"
            name="description"
            style={{ marginBottom: 10 }}
            rules={[{ required: true, message: "Vui lòng thêm mô tả!" }]}
          >
            <TextArea placeholder={"Mô tả..."} autoSize />
          </Form.Item>
          <Form.Item
            label={"Category"}
            name="categories"
            style={{ marginBottom: 5 }}
          >
            <Select
              mode="multiple"
              placeholder="Chọn Category"
              options={appState?.categories?.map((item: any) => ({
                ...item,
                value: item.code,
                label: item.name,
              }))}
            />
          </Form.Item>
          <UploadImage
            name={"iamge"}
            label={"Image"}
            onChangeImage={onChangeImage}
            url={state?.image}
            // required
            // disabled={!isNullOrEmpty(state?.detail)}
          />
          {isNullOrEmpty(state?.detail) && (
            <div className="p-[10px] bg-[rgba(1,1,1,0.05)] rounded-[5px] mt-[30px]">
              <div className="font-medium text-[16px] mb-[5px]">Mở đầu</div>
              <div>
                <Form.Item
                  label="Content"
                  name="content"
                  style={{ marginBottom: 10 }}
                  rules={[
                    { required: true, message: "Vui lòng thêm content!" },
                  ]}
                >
                  <TextArea placeholder={"Content..."} autoSize />
                </Form.Item>
                <Form.Item
                  label="Option 1"
                  name="option1"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true, message: "Vui lòng thêm option!" }]}
                >
                  <TextArea
                    placeholder={"Content..."}
                    autoSize
                    // onBlur={updateOption?.bind(null, "1")}
                  />
                </Form.Item>
                <Form.Item
                  label="Option 2"
                  name="option2"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: true, message: "Vui lòng thêm option!" }]}
                >
                  <TextArea
                    placeholder={"Content..."}
                    autoSize
                    // onBlur={updateOption?.bind(null, "2")}
                  />
                </Form.Item>
              </div>
            </div>
          )}
        </Form>
      )}
    </Drawer>
  );
};
export default DetailStoryModal;
